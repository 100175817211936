import React from "react";

import AuthUserContext from "components/Session/AuthUserContext";
import withAuthorization from "components/Session/withAuthorization";

import { Container, Header, Next } from "components/Onboarding/OnboardingComponents";

import { pushOnboarding } from "api/db";

function MotivationOnboarding() {
  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <Container>
          <Header />
          <section>
          <span>2/3</span>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                Why do you want to learn to code?
              </h1>
              <Next
                paintDrip
                to="/onboarding/questions/experience"
                hex="#438cee"
                duration={1}
                onClick={() => {
                  pushOnboarding(authUser.uid, "motivation", "Advance my career")
                }
                }
              >
                Advance my career
              </Next>
              <br />
              <Next
                paintDrip
                to="/onboarding/questions/experience"
                hex="#438cee"
                duration={1}
                onClick={() =>
                  pushOnboarding(authUser.uid, "motivation", "Explore my interests")
                }
              >
                Explore my interests
              </Next>
              <br />
              <Next
                paintDrip
                to="/onboarding/questions/experience"
                hex="#438cee"
                duration={1}
                onClick={() =>
                  pushOnboarding(authUser.uid, "motivation", "Other")
                }
              >
                Other
              </Next>
            </div>
          </section>
        </Container>
      )}
    </AuthUserContext.Consumer>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(MotivationOnboarding);
